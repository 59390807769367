import 'core-js/modules/es.array.find';

function ready(callback){
  if (document.readyState!='loading') callback();
  else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
}

ready(function(){
  // 最善の言語の版へリダイレクトする
  let map = [
      [/en(-.+)?/i, "en"],
      [/ja(-.+)?/i, "ja"],
      [/zh-cn/i, "zh-CN"],
      [/zh(-.+)?/i, "zh-CN"],
  ];

  let candidates = navigator.languages ? navigator.languages.slice() : [];
  candidates.push("ja");

  let locale = null;
  candidates.find((candidate) => {
      map.find(([test, answer]) => {
          if (candidate.match(test)) {
              locale = answer;
              return true;
          }
      });
      if (locale) { return true; }
  });

  window.location = "/" + locale;
});
